import { makeStyles } from "@mui/styles";
import { Grid, Box } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import SignInImage from "../../assets/images/Auth/signin.png";
import { logo, logo3 } from "../../assets";
//---------------components -------->
import { AuthCard } from "../../components";
const useStyles = makeStyles((theme) => ({
  cardMedia: {
    borderRadius: "12px",
    width: "40% !important",
  },
  verticalCenter: {
    alignItems: "center",
    display: "flex !important",
  },
  logoImageXs: {
    width: 192,
    cursor: "pointer",
    marginBottom: 30,
  },
  cardContent: {
    "&.MuiCardContent-root": {
      padding: "50px !important",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        padding: "30px !important",
      },
    },
  },
}));
export function LoginWrapper(props) {
  const classes = useStyles();
  return (
    <AuthCard>
      <Grid container>
        <Grid item xs={12} md={5} sx={{ display: { xs: "none", md: "flex" } }}>
          <CardMedia
            component="img"
            alt="Rectangle"
            width="40%"
            image={SignInImage}
            classname={classes.cardMedia}
            sx={{
              height: { xs: "100%", md: "100%" },
            }}
          />
        </Grid>
        <Grid item xs={12} md={7} className={classes.verticalCenter}>
          <CardContent className={classes.cardContent}>
            <Box
              className={classes.logoContainer}
              sx={{
                mr: 1,
                textAlign: "center",
                display: { xs: "none", md: "block" },
              }}
            >
              <img className={classes.logoImageXs} alt="logo" src={logo3} />
            </Box>
            {props.children}
          </CardContent>
        </Grid>
      </Grid>
    </AuthCard>
  );
}
// ==============================|| Login PAGE ||============================== //
